//script 01
elasticApm.init({
    serviceName: 'processevo-dorilux-webshop-js',
    serverUrl: 'https://' + window.location.hostname + ':20200', //TODO: refactor - this should be calculated somewhere else.
    active: false
})

// start ProcessEvo on document ready
$(function () {
    //TODO: refactor - why do we need this at all?
    // window._env_ = {}
    // window._env_.DESIGN_SYSTEM = 'dorilux-ecommerce'

    ProcessEvoApp.init();
});

//script 02
		
window.onscroll = function() {scrollFunction()};
//TODO: refactor
function scrollFunction() {
    if (document.body.scrollTop > 48 || document.documentElement.scrollTop > 48) {
        var headerHeigh = $('#header').outerHeight();
        var bredcrumbsHeigh = $('nav.breadcrumbs').outerHeight();

        //If bredcrumbs dosent exsist (i.e. undefined), set it height size to 0
        if (typeof(bredcrumbsHeigh) === 'undefined') {
            bredcrumbsHeigh = 0;
        }
        //Gets total height and convert it to "em" units (1em = 16px)
        var mainOfset = ((headerHeigh + bredcrumbsHeigh) / 16) +"em";

        document.getElementById("header").classList.add("sticky");
        $("nav.breadcrumbs").addClass("sticky-breadcrumb");
        $("nav.sticky-breadcrumb").css({"top": (headerHeigh - 48) / 16 +"em"});
        $("#main-container").css({"padding-top": mainOfset});
        $("#overlay-panel-search, #overlay-panel-cart").css({"top": (headerHeigh - 48) / 16 +"em"});

        if (window.matchMedia('(min-width: 1024px)').matches) {
            $("#prd-main-image, #scrollViewVertical, .basket__details").css({"position":"sticky","top": ( ( (headerHeigh + bredcrumbsHeigh) - 24) / 16) +"em"});
        } else {
            $("#prd-main-image, #scrollViewVertical, .basket__details").css({"position":"","top": ""});
        }

        var imgHeigh = $('#sales .prd-image, #promotions .prd-image, #related-products .prd-image').outerHeight();
        var sizeNameHeigh = $('#sales .prd-size-name, #promotions .prd-size-name, #related-products .prd-size-name').outerHeight();
        var priceHeigh = $('#sales .prd-price, #promotions .prd-price, #related-products .prd-price').outerHeight()+20;
        
        if ( typeof(sizeNameHeigh) === 'undefined') {
            sizeNameHeigh = 0;
        }

        var nameHeigh = 0;
        $('#sales .prd-name, #promotions .prd-name, #related-products .prd-name').each(function(){
            if ($(this).outerHeight() > nameHeigh) {
                nameHeigh = $(this).outerHeight();
            }
        });

        var scrollerHeigh = imgHeigh + nameHeigh + sizeNameHeigh + priceHeigh;
        $("ul.k-scrollview-wrap, li.k-scrollview-page, .k-widget.k-scrollview").css({"height": scrollerHeigh});

    } else {
        document.getElementById("header").classList.remove("sticky");
        $("nav.sticky-breadcrumb").css({"top":""}); 
        $("nav.breadcrumbs").removeClass("sticky-breadcrumb");
        $("#main-container").css({"padding-top":""});
        $("#overlay-panel-search, #overlay-panel-cart").css({"top":""});

        if (window.matchMedia('(min-width: 1024px)').matches) {
            $("#prd-main-image, #scrollViewVertical, .basket__details").css({"position":"","top": ""});
            
        } else {
            $("#prd-main-image, #scrollViewVertical, .basket__details").css({"position":"sticky","top": ( ( (headerHeigh + bredcrumbsHeigh) - 24) / 16) +"em"});
        }
    }

}

//TODO: refactor - why do we need that?
$(document).ready(function(){
    $(".app-main-nav--mobile span").click(function() {
        $(".burger-button").toggleClass("m-button--close");
        $(".app-main-nav--mobile-content").toggleClass("m-nav--show");
    });
});
